<template>
  <div class="w-100 p-2 mt-2">
    <page-header>
      <i class="fas fa-list"></i>
    </page-header>
    <page-card>
      <template v-slot:header>
        Turnover rewards
      </template>
      <template v-slot:content>
        <div class="sygni-grid">
          <div class="row mt-3">
            <div class="col-12">
              <grid-pagination :total-count="totalCount"
                               :per-page="perPage"
                               :current-page="currentPage"
                               @load-items="paginationReload"></grid-pagination>

              <div class="table-responsive">
                <b-table striped show-empty
                         :empty-text="$t('table.no_results')"
                         :busy="isBusy"
                         hover
                         head-variant="dark"
                         outlined
                         :sort-by.sync="orderColumn"
                         :sort-desc.sync="orderDirection"
                         :no-local-sorting="true"
                         :items="items"
                         :fields="fields">

                  <template #cell(miner)="data">
                    <span>{{ data.value ? 'yes' : 'no' }}</span>
                  </template>

                  <template #cell(digger)="data">
                    <span>{{ data.value ? 'yes' : 'no' }}</span>
                  </template>

                  <template #cell(african)="data">
                    <span>{{ data.value ? 'yes' : 'no' }}</span>
                  </template>

                  <template #cell(global)="data">
                    <span>{{ data.value ? 'yes' : 'no' }}</span>
                  </template>


                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{ $t('state.loading') }}</strong>
                    </div>
                  </template>

                </b-table>
              </div> <!-- #table-responsive-->

              <grid-pagination :total-count="totalCount"
                               :per-page="perPage"
                               :current-page="currentPage"
                               @load-items="paginationReload"></grid-pagination>

            </div>
          </div>
        </div>
      </template>
    </page-card>
  </div>
</template>

<script>

import GridPagination from "../../../components/grid/grid-pagination/GridPagination";
import PageHeader from "../../../components/page/PageHeader";
import PageCard from "../../../components/page/PageCard";
import {mapState} from "vuex";

export default {
  props: ['apiKey'],
  components: {
    PageCard,
    PageHeader,
    GridPagination,
  },
  data() {
    return {
      orderColumn: null,
      orderDirection: false,
      fields: [
        {key: 'id', label: this.$t('table.header.id')},
        {key: 'email', label: this.$t('table.header.clientEmail')},
        {key: 'digger', label: 'Digger (10g Gold Bar)'},
        {key: 'miner', label: 'Miner (50g Gold Bar)'},
        {key: 'african', label: 'African (300g Gold Bar)'},
        {key: 'global', label: 'Global (1200g Gold Bar)'}

      ],
      selectOptions: {
        bulkActions: [
          {value: null, text: 'Akcje masowe'}
        ],
      },
      filterQuery: [],
    };
  },
  mounted() {
    if (this.apiKey) {
      this.$store.commit('root/setApiKey', this.apiKey);
    }
    this.loadItems();
    document.addEventListener('keydown', this.bindEnter);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.bindEnter);
  },
  computed: {
    ...mapState({
      perPage: (state) => state.turnoverRewardsStore.perPage,
      currentPage: (state) => state.turnoverRewardsStore.currentPage,
      items: (state) => state.turnoverRewardsStore.items,
      isBusy: (state) => state.turnoverRewardsStore.isBusy,
      totalCount: (state) => state.turnoverRewardsStore.totalCount,
    }),
    filters: function () {
      return this.$store.getters["root/filter"]('turnoverRewards');
    },
  },
  methods: {
    storeFilter(data) {
      this.$store.commit('root/setFilter', {storeName: 'turnoverRewards', ...data})
    },
    createSortQuery() {
      let sorting;
      if (this.orderColumn) {
        if (this.orderDirection === true) {
          sorting = encodeURIComponent('+' + this.orderColumn);
        } else {
          sorting = encodeURIComponent('-' + this.orderColumn);
        }
        return '&sort[]=' + sorting;
      }
      return '';
    },
    bindEnter(e) {
      if (e.keyCode === 13) {
        this.applyFilters();
      }
    },
    setPerPage(page) {
      this.setPerPage = page;
    },
    loadItems() {
      this.$store.dispatch('turnoverRewardsStore/loadItems', {
        params: {
          offset: this.perPage * (this.currentPage - 1),
          limit: this.perPage,
        },
        filters: this.filterQuery,
        sort: this.createSortQuery()
      });
    },
    changePage: function (currentPage) {
      this.$store.commit('shipmentStore/setCurrentPage', currentPage);
    },
    applyFilters: function () {
      this.changePage(1);
      this.loadItems();
    },
    resetFilters() {
      this.filterQuery = [];
      this.$root.$emit('resetFilters');
      this.initFilters();
      this.applyFilters();
    },
    initFilters() {
      this.$store.commit('root/initFilters', {
        storeName: 'shipment',
        filterNames: ['date', 'profit']
      });
    },
    paginationReload(data) {
      this.changePage(data.currentPage);
      this.$store.commit('shipmentStore/setPerPage', data.perPage);
      this.loadItems();
    },
  },
  watch: {
    orderColumn: function () {
      this.loadItems();
    },
    orderDirection: function () {
      this.loadItems();
    },
    filterQuery: function () {

    }
  }
}
</script>


